@if (package$ | async; as package) {
	<div data-test-id="package-detail">
		<vp-carousel [items]="images" />
		<section class="section-spacing bg-white">
			<div class="section-gap mx-auto max-w-screen-2xl">
				<div
					class="flex w-full flex-col gap-content-section md:mx-auto md:flex-row lg:w-10/12"
				>
					<div class="flex w-full flex-col gap-8 lg:w-1/2 xl:w-2/3">
						<div>
							<header data-test-class="package-detail-header">
								<h1
									class="heading-group heading-group-lg"
									data-test-class="title"
								>
									{{ package.name }}
								</h1>
								<p
									class="heading-group-overline"
									data-test-class="overline"
									role="doc-subtitle"
								>
									{{ 'global.package-label' | translate }}
								</p>
							</header>
						</div>
						<vp-read-more>
							<markdown
								[data]="package.description"
								data-test-class="package-detail-description"
							/>
						</vp-read-more>
						<h2
							class="running-head running-head-center"
							data-test-class="variants-header"
						>
							@if (package.packages.length > 1) {
								{{
									'storyblok.pages.choose-your-package'
										| translate
								}}
							} @else {
								{{ 'storyblok.pages.your-package' | translate }}
							}
						</h2>
						<vp-tab-menu
							[activeTab]="activePackageIndex"
							[hideTabs]="package.packages.length <= 1"
							[useButtonVariant]="true"
							(selectedActiveTab)="setActivePackageIndex($event)"
							data-test-id="package-variants"
						>
							@for (
								packageVariant of package.packages;
								track $index
							) {
								<vp-tab-menu-item
									[content]="activePackageVariant"
									[label]="packageVariant.name"
									[name]="'variant-' + ($index + 1)"
								>
									<p
										class="heading-group heading-group-sm font-semibold"
									>
										{{
											'storyblok.pages.package-includes'
												| translate
										}}
									</p>
									<markdown
										[data]="packageVariant.bulletContent"
										class="list-checkmarks"
										data-test-id="package-detail-description"
									/>
								</vp-tab-menu-item>
							}
							<ng-template #activePackageVariant>
								<p
									class="heading-group heading-group-sm font-semibold"
								>
									{{
										'storyblok.pages.package-includes'
											| translate
									}}
								</p>
								<markdown
									[data]="
										package.packages[activePackageIndex]
											.bulletContent
									"
									class="list-checkmarks"
									data-test-id="package-detail-description"
								/>
							</ng-template>
						</vp-tab-menu>
						@if (package.hotel) {
							<h2
								class="running-head running-head-center"
								data-test-class="hotel-header"
							>
								{{
									'storyblok.pages.hotel-information'
										| translate
								}}
							</h2>
							@if (package.hotel.images?.length > 0) {
								<vp-mini-carousel
									[items]="hotelImages"
									aspectRatio="21:9"
								/>
							}
							<vp-tab-menu data-test-id="package-hotel-info">
								<vp-tab-menu-item
									[content]="facilitiesRef"
									[label]="'global.facilities' | translate"
									name="facilities"
								/>
								<vp-tab-menu-item
									[content]="goodToKnowsRef"
									[label]="'global.good-to-know' | translate"
									name="good-to-knows"
								/>
							</vp-tab-menu>
							<ng-template #facilitiesRef>
								<ng-container
									[ngTemplateOutlet]="list"
									[ngTemplateOutletContext]="{
										listItems:
											package.hotel?.facilities || [],
										grid: true,
										name: 'facilities',
									}"
								/>
							</ng-template>
							<ng-template #goodToKnowsRef>
								<ng-container
									[ngTemplateOutlet]="list"
									[ngTemplateOutletContext]="{
										listItems:
											package.hotel?.goodToKnows || [],
										grid: false,
										name: 'good-to-knows',
									}"
								/>
							</ng-template>
						}
						<h2
							class="running-head running-head-center"
							data-test-class="faq-header"
						>
							{{ 'storyblok.pages.faq-title' | translate }}
						</h2>
						<div
							class="flex flex-col gap-4"
							data-test-id="package-faqs"
						>
							<vp-accordion
								[isOpen]="true"
								[title]="
									'storyblok.pages.package-terms-and-conditions'
										| translate
								"
							>
								<markdown [data]="package.termsAndConditions" />
							</vp-accordion>
							@for (faq of package.faqs; track $index) {
								<vp-accordion [title]="faq.question">
									<markdown [data]="faq.answer" />
								</vp-accordion>
							}
						</div>
					</div>
					<div class="flex w-full flex-col gap-4 lg:w-1/2 xl:w-1/3">
						<div
							class="card card-lg flex flex-col gap-4"
							data-test-class="active-package-card"
						>
							@if (
								package.packageAvailableSoon &&
								!package.packageExpired
							) {
								<ng-container
									[ngTemplateOutlet]="notAvailable"
									[ngTemplateOutletContext]="{
										title: 'storyblok.pages.package-available-soon.title',
										content:
											'storyblok.pages.package-available-soon.content',
									}"
								/>
							}
							@if (
								package.packageExpired &&
								!package.packageAvailableSoon
							) {
								<ng-container
									[ngTemplateOutlet]="notAvailable"
									[ngTemplateOutletContext]="{
										title: 'storyblok.pages.package-expired.title',
										content:
											'storyblok.pages.package-expired.content',
									}"
								/>
							}
							@if (
								!package.packageAvailableSoon &&
								!package.packageExpired
							) {
								<vp-pricing
									[conditions]="
										'components.pricing.per-person-per-night'
											| translate
									"
									[currency]="
										package.packages[activePackageIndex]
											.price.currency
									"
									[isHorizontal]="true"
									[price]="
										package.packages[activePackageIndex]
											.price.amount
									"
									data-test-class="package-price"
									size="lg"
								/>
								<vp-package-details
									[package]="{
										title: package.name,
										variation:
											package.packages[activePackageIndex]
												.name,
									}"
									className="hidden lg:block"
								/>
								<a
									[href]="
										this.bookingtool +
										this.hotelSlug +
										'?packageGUID=' +
										package.packages[activePackageIndex]
											.GUID
									"
									class="btn btn-primary w-full"
									data-test-class="package-cta-button"
								>
									{{
										'storyblok.pages.package.button-cta'
											| translate
									}}
								</a>
							}
						</div>
						@if (package.hotel?.rating) {
							<vp-rating
								[numberOfRatings]="
									package.hotel.rating.numberOfRatings
								"
								[score]="package.hotel.rating.score"
							/>
						}
					</div>
				</div>
			</div>
		</section>
	</div>
	<vp-sheet
		[isLoading]="false"
		[triggerId]="'facilities-trigger-sheet'"
		sheetWidth="third"
	>
		<vp-sheet-header>
			<span class="h2 block">
				{{ 'global.facilities' | translate }}
			</span>
		</vp-sheet-header>
		<vp-sheet-content id="sheet-content" tabindex="0">
			<div class="list-checkmarks">
				<ul>
					@for (
						item of package.hotel?.facilities || [];
						track $index
					) {
						<li>{{ item }}</li>
					}
				</ul>
			</div>
		</vp-sheet-content>
	</vp-sheet>
	<vp-sheet
		[isLoading]="false"
		[triggerId]="'good-to-knows-trigger-sheet'"
		sheetWidth="third"
	>
		<vp-sheet-header>
			<span class="h2 block">
				{{ 'global.good-to-know' | translate }}
			</span>
		</vp-sheet-header>
		<vp-sheet-content id="sheet-content" tabindex="0">
			<div class="list-checkmarks">
				<ul>
					@for (
						item of package.hotel?.goodToKnows || [];
						track $index
					) {
						<li>{{ item }}</li>
					}
				</ul>
			</div>
		</vp-sheet-content>
	</vp-sheet>
}

<ng-template #list let-grid="grid" let-listItems="listItems" let-name="name">
	<div [id]="name" class="list-checkmarks list-grid flex flex-col gap-4">
		<ul
			[ngClass]="{ 'sm:grid-cols-2': grid }"
			class="grid grid-cols-1 gap-4"
		>
			@for (
				item of listItems | slice: 0 : (grid ? 10 : 5);
				track $index
			) {
				<li>{{ item }}</li>
			}
		</ul>
		<button
			[id]="name + '-trigger-sheet'"
			class="text-link w-fit cursor-pointer"
			vp-sheet-trigger
		>
			{{ 'global.show-more' | translate }}
		</button>
	</div>
</ng-template>

<ng-template #notAvailable let-content="content" let-title="title">
	<p
		[attr.data-test-id]="'package-no-available-title'"
		class="heading-group heading-group-sm font-semibold"
	>
		{{ title | translate }}
	</p>
	<p [attr.data-test-id]="'package-no-available-content'">
		{{ content | translate }}
	</p>
	<a
		[href]="parentUrl"
		class="btn btn-primary w-full"
		data-test-id="package-no-available-button"
	>
		{{ 'storyblok.pages.package.button-no-available' | translate }}
	</a>
</ng-template>
