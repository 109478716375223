<div data-test-id="meetingroom-overview">
	@for (blok of content; track blok) {
		<div>
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
				/>
			}
		</div>
	}
	<section class="section-spacing bg-accent-10">
		<div
			class="section-gap mx-auto max-w-screen-2xl children:w-full children:md:mx-auto children:lg:w-10/12"
		></div>
	</section>
</div>
