import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { LinkComponent } from '@valk-nx/components/ui-link/src/lib/link';
import { BaseDirective } from '@valk-nx/core/lib/directives/base/base.directive';
import { LinkModule } from '@valk-nx/core/lib/directives/link/link.module';
import { WhatsappModule } from '@valk-nx/core/lib/pipes/whatsapp/whatsapp.module';

import {
	FooterInformation,
	FooterMenu,
	FooterMenuLink,
	FooterUSP,
} from './footer.interface';

type Nullable<T> = T | null;

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	selector: 'vp-footer',
	templateUrl: './footer.html',
	imports: [
		CommonModule,
		LinkModule,
		LinkComponent,
		AngularSvgIconModule,
		WhatsappModule,
		RouterModule,
		TranslateModule,
	],
})
export class FooterComponent extends BaseDirective {
	@Input() bottomItems: Nullable<FooterMenuLink[]> = null;
	@Input() information: Nullable<FooterInformation> = null;
	@Input() isMinimal = false;
	@Input() title = '';
	@Input() menu: FooterMenu = {
		items: [
			{
				title: '',
				items: [
					{
						title: '',
						url: '',
					},
				],
			},
		],
	};
	@Input() usps: FooterUSP[] = [
		{
			icon: 'assets/icons/logo-valk-white.svg',
			text: '',
			url: '',
		},
		{
			icon: 'assets/icons/logo-valk-white.svg',
			text: 'Valk verrast',
			url: '',
		},
	];

	@Input() socials: {
		facebook: string | null;
		instagram: string | null;
		tiktok: string | null;
		youtube: string | null;
		linkedin: string | null;
		pinterest: string | null;
	} | null = {
		facebook: 'https://www.facebook.com/VanderValkVerrast',
		instagram: 'https://www.instagram.com/valkverrast',
		tiktok: null,
		youtube: null,
		linkedin: null,
		pinterest: null,
	};

	@Output() toTop: EventEmitter<void> = new EventEmitter<void>();

	constructor(public override translate: TranslateService) {
		super(translate);
	}

	scrollToTop(): void {
		this.toTop.emit();
		window.scrollTo({
			behavior: 'smooth',
			left: 0,
			top: 0,
		});
	}
}
