import type { Config } from 'tailwindcss';

import config from './libs/tailwind/src/configs/index';
import accordion from './libs/tailwind/src/plugins/accordion/accordion';
import button from './libs/tailwind/src/plugins/button/button';
import card from './libs/tailwind/src/plugins/card/card';
import children from './libs/tailwind/src/plugins/children/children';
import divider from './libs/tailwind/src/plugins/divider/divider';
import elevation from './libs/tailwind/src/plugins/elevation/elevation';
import footer from './libs/tailwind/src/plugins/footer/footer';
import form from './libs/tailwind/src/plugins/form/form';
import headingGroup from './libs/tailwind/src/plugins/heading-group/heading-group';
import iconContainer from './libs/tailwind/src/plugins/icon-container/icon-container';
import inputControl from './libs/tailwind/src/plugins/input-control/input-control';
import list from './libs/tailwind/src/plugins/list/list';
import loyalCard from './libs/tailwind/src/plugins/loyal-card/loyal-card';
import overlay from './libs/tailwind/src/plugins/overlay/overlay';
import notSelector from './libs/tailwind/src/plugins/not-selector/not-selector';
import pricing from './libs/tailwind/src/plugins/pricing/pricing';
import ribbon from './libs/tailwind/src/plugins/ribbon/ribbon';
import runningHead from './libs/tailwind/src/plugins/running-head/running-head';
import sectionSpacing from './libs/tailwind/src/plugins/section-spacing/section-spacing';
import tag from './libs/tailwind/src/plugins/tag/tag';
import textBorder from './libs/tailwind/src/plugins/text-border/text-border';
import textLink from './libs/tailwind/src/plugins/text-link/text-link';
import base from './libs/tailwind/src/plugins/typography/base';
import spacings from './libs/tailwind/src/plugins/typography/spacings';
import typography from './libs/tailwind/src/plugins/typography/typography';
import breakpoint from './libs/tailwind/src/plugins/breakpoint/breakpoint';

export default {
	content: ['src/**/*.{html,ts}'],
	theme: {
		extend: {
			animation: {
				'fade-in': 'fade-in 0.3s ease-out',
				loading: 'loading 2000ms infinite',
			},
			aspectRatio: {
				classic: '4 / 3',
				film: '3 / 2',
				anamorphic: '21 / 9',
				'ultra-wide': '32 / 9',
			},
			borderRadius: {
				input: 'var(--inputs-border-radius, 0.25rem)',
				'tab-menu': 'var(--tab-menu-border-radius, 0)',
			},
			boxShadow: {
				border: '0 0 0 1px',
				'item-focus': '0px 0px 8px 0px',
				'inner-sm': 'inset 0px 2px 1px',
			},
			content: {
				empty: '""',
				unset: 'unset',
			},
			height: {
				'0%': '0%',
				inherit: 'inherit',
			},
			gap: {
				'content-section': 'var(--section-gap, 1rem)',
			},
			keyframes: {
				loading: {
					from: { backgroundPositionX: '100%' },
					to: { backgroundPositionX: '-100%' },
				},
				'fade-in': {
					'0%': { opacity: '0%' },
					'100%': { opacity: '100%' },
				},
			},
			margin: config.spacings,
			maxWidth: {
				'1/2': '50%',
			},
			padding: config.spacings,
			rotate: {
				135: '135deg',
				225: '225deg',
				315: '315deg',
			},
			screens: {
				xs: '320px',
				'max-md': { max: '767px' },
				xl: '1248px',
				'3xl': '1728px',
			},
			textColor: config.colors.textColors,
			transitionProperty: {
				sheet: 'top, right, bottom, left, auto',
				width: 'width',
			},
			transitionTimingFunction: {
				basic: '0.4s ease',
			},
			width: {
				'1/10': '10%',
				'2/10': '20%',
				'3/10': '30%',
				'4/10': '40%',
				'5/10': '50%',
				'6/10': '60%',
				'7/10': '70%',
				'8/10': '80%',
				'9/10': '90%',
				inherit: 'inherit',
			},
		},
		colors: config.colors.colors,
		fontFamily: {
			'sans-serif': ['Source Sans Pro', 'Arial', 'sans-serif'],
			logo: ['Melbourne', 'Helvetica', 'Arial', 'sans-serif'],
			display: ['Raleway', 'Helvetica', 'Arial', 'sans-serif'],
			extra: ['PT Sans', 'Arial', 'sans-serif'],
		},
		fontSize: {
			xxs: ['0.625rem', { lineHeight: '1' }],
			xs: ['0.75rem', { lineHeight: '1.625' }],
			sm: ['0.875rem', { lineHeight: '1.625' }],
			base: ['1rem', { lineHeight: '1.625' }],
			lg: ['1.125rem', { lineHeight: '1.5' }],
			xl: ['1.25rem', { lineHeight: '1.375' }],
			'2xl': ['1.5rem', { lineHeight: '1.25' }],
			'3xl': ['1.875rem', { lineHeight: '1.25' }],
			'4xl': ['2.25rem', { lineHeight: '1' }],
			'5xl': ['2.5rem', { lineHeight: '1' }],
			'6xl': ['3rem', { lineHeight: '1' }],
			'7xl': ['3.75rem', { lineHeight: '1' }],
			'8xl': ['4.5rem', { lineHeight: '1' }],
			'9xl': ['6rem', { lineHeight: '1' }],
			inherit: ['inherit', { lineHeight: 'inherit' }],
		},
	},
	safelist: config.safelist,
	plugins: [
		typography,
		children,
		notSelector,
		overlay,
		base,
		spacings,
		accordion,
		button,
		card,
		inputControl,
		elevation,
		divider,
		footer,
		form,
		list,
		loyalCard,
		ribbon,
		runningHead,
		textBorder,
		textLink,
		headingGroup,
		iconContainer,
		tag,
		pricing,
		sectionSpacing,
		breakpoint
	],
} satisfies Config;
