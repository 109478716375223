import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import { DynamicModule } from 'ng-dynamic-component';

import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Components } from '@valk-nx/storyblok-core/src/lib/components';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import { HOTEL_SLUG } from '@valk-nx/storyblok-services/src/lib/services/content.service';

@Component({
	selector: 'sb-meetingroom-overview',
	templateUrl: './meetingroom-overview.component.html',
	standalone: true,
	imports: [
		CommonModule,
		DynamicModule,
		StoryblokDirective,
		PersuadeCardComponent,
	],
})
export class MeetingroomOverviewComponent extends StoryblokRootDirective {
	@Input({ required: true }) content!: unknown[];

	// Needed to "mute" errors in console
	@Input({ required: true }) canonical!: string;
	@Input({ required: true }) hideFromSitemap!: boolean;
	@Input({ required: true }) openGraphDescription!: string;
	@Input({ required: true }) openGraphImage!: { alt: string; file: string };
	@Input({ required: true }) openGraphTitle!: string;
	@Input({ required: true }) openGraphType!: string;
	@Input({ required: true }) metaDescription!: string;
	@Input({ required: true }) metaTitle!: string;
	@Input({ required: true }) robotsNoIndex!: boolean;
	@Input({ required: true }) robotsNoFollow!: boolean;

	components: typeof Components;

	currentUrl = '';

	constructor(
		private router: Router,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
	) {
		super();

		// eslint-disable-next-line @nx/enforce-module-boundaries
		import('@valk-nx/storyblok-core/src/lib/components')
			.then((cp) => {
				this.components = cp.Components;
			})
			.catch(
				/* istanbul ignore next */ (error) => {
					console.error(error);
				},
			);

		this.currentUrl = this.router.url;
	}
}
